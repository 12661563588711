import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { Flex } from '@/components/base/flex';
import { Text } from '@/components/base/text';
import { useViewportSizes } from '@/hooks/use-viewport-sizes';
import { makeButton, StyledButton } from '../base/buttons';
import { getPxNumber } from '@/utils/styles';

const Button = makeButton({
  ButtonComponent: StyledButton.withComponent('div'),
  displayName: 'DivNavButton',
});

type NavItemProps = {
  text?: string;
  href?: string;
  active?: boolean;
  iconUrl?: string;
  children?: ReactNode;
};

const DROP_DOWN_WIDTH = 200;

const DropDown = styled.div`
  padding: ${({ theme }) => theme.space.xs}px 0;
  margin: 2px 0 0;
  background-color: ${({ theme }) => theme.colors.navBackgroundHover};
  z-index: ${({ theme }) => theme.zIndices.navigation};
  display: none;
  &:after {
    content: '';
    border-left: 5px solid ${({ theme }) => theme.colors.transparent};
    border-right: 5px solid ${({ theme }) => theme.colors.transparent};
    border-bottom: 7px solid ${({ theme }) => theme.colors.navBackgroundHover};
    position: absolute;
    top: -7px;
    right: 10px;
  }
  ${({ theme }) => theme.mediaQueries.l} {
    box-shadow: 0 1px 3px rgb(0 0 0 / 30%);
    position: absolute;
    right: 0;
    top: 100%;
    min-width: ${DROP_DOWN_WIDTH}px;
  }
`;

const StyledLink = styled(Button)<{ isOpen?: boolean }>`
  padding: ${({ theme }) => theme.space.s}px;
  text-transform: uppercase;
  position: relative;
  border: 1px solid ${({ theme }) => theme.colors.transparent};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${({ isOpen, theme }) =>
    isOpen &&
    `
    background-color: ${theme.colors.navBackgroundHover};
   `}
  &:hover {
    background-color: ${({ theme }) => theme.colors.navBackgroundHover};
  }
  &:focus,
  &:active {
    border: 1px solid ${({ theme }) => theme.colors.primary200};
  }
  &:hover,
  &:active {
    ${DropDown} {
      display: block;
    }
  }
  ${({ theme }) => theme.mediaQueries.l} {
    padding: 0 ${({ theme }) => theme.space.xs}px;
  }
  ${({ theme }) => theme.mediaQueries.l} {
    background-color: ${({ theme }) => theme.colors.transparent};
  }
`;

StyledLink.defaultProps = {
  variant: 'transparent',
};

const StyledFlex = styled(Flex)`
  height: 100%;
  cursor: pointer;
`;

type LinkWrapperProps = {
  children: ReactNode;
  href: string;
};

const LinkWrapper = ({ children, href }: LinkWrapperProps) => {
  if (href) {
    return <Link href={href}>{children}</Link>;
  }
  return <>{children}</>;
};

export const NavItem: React.FC<NavItemProps> = ({
  text,
  href = '',
  active,
  iconUrl,
  children,
}) => {
  const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const { isExtraLarge, isLarge } = useViewportSizes();
  const theme = useTheme();
  const isDropDown = !!children;

  useEffect(() => {
    setIsMobile(!isLarge && !isExtraLarge);
  }, [isLarge, isExtraLarge]);

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isMobile) return;
    isDropDown && e.preventDefault();

    setShow(!show);
  };

  const borderRadii = useMemo(() => {
    return {
      m: getPxNumber(theme.radii.m) ?? 1,
      s: getPxNumber(theme.radii.s) ?? 1,
      xs: getPxNumber(theme.radii.xs) ?? 1,
    };
  }, [theme.radii.m, theme.radii.s, theme.radii.xs]);

  return (
    <>
      <LinkWrapper href={href}>
        <StyledLink isOpen={show} onClick={handleClick}>
          <StyledFlex alignItems="center">
            {iconUrl && (
              <Image
                src={iconUrl}
                width={borderRadii.m}
                height={borderRadii.s}
                alt={text ?? ''}
              />
            )}
            {text && (
              <Text
                paddingRight={isDropDown ? '2xs' : ''}
                variant="4xs"
                color="navText"
                marginLeft="3xs"
              >
                {text}
              </Text>
            )}
            {isDropDown && (
              <Image
                src="/images/caret.svg"
                width={borderRadii.xs}
                height={borderRadii.xs}
                alt=""
              />
            )}
          </StyledFlex>
          {isDropDown && <DropDown>{children}</DropDown>}
        </StyledLink>
      </LinkWrapper>
    </>
  );
};
