import React, { useState, useRef, MouseEventHandler, useEffect } from 'react';
import styled from '@emotion/styled';
import Image from 'next/image';

import caretDown from '/public/images/caretDown.svg';
import { Palette } from '@/constants/palette';
import { Flex, FlexProps } from '@/components/base/flex';
import { Box } from '@/components/base/box';
import { Text } from '@/components/base/text';
import { Error } from '@/components/base/error';
import useOnClickOutside from '@/hooks/useOnClickOutside';

export interface DropdownSelectOption {
  label: string;
  value: string;
  description?: string;
  isSale?: boolean;
}

export interface DropdownSelectProps {
  /**
   * The value of the DropdownSelect component.
   * @example
   * value = "option1"
   */
  defaultValue?: DropdownSelectOption;
  /**
   * If `true`, the element will be disabled.
   * It will set the `disabled` HTML attribute
   * @example
   * disabled
   */
  disabled?: boolean;
  /**
   * The options of DropdownSelect component.
   * @example
   * options = [{value: 'option1', label: 'Option 1'}]
   */
  options: DropdownSelectOption[];
  onChange: (e: DropdownSelectOption) => void;
  placeholder?: string;
  error?: string;

  /**
   * The width of the DropdownSelect component.
   */
  fullWidth?: boolean;
}

type DropdownBodyProps = {
  isOpen: boolean;
};

const DropDown = styled(Box)<{ disabled?: boolean }>`
  border-radius: ${({ theme }) => theme.radii['3xs']};
  ${(props) =>
    props.disabled &&
    `
    opacity: 0.6;
    pointer-events: none;
  `}
  position: relative;
`;

const DropdownHeader = styled(Flex)<DropdownBodyProps>`
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  ${(props) => props.isOpen && 'border-bottom: none'};
  padding: 0 ${({ theme }) => theme.space.xs}px;
  cursor: pointer;
  align-items: center;
  position: relative;
  height: 30px;
  justify-content: space-between;
`;

const DROP_DOWN_WIDTH = 220;
const DROP_DOWN_HEIGHT = 300;

const DropdownBody = styled(Box)<DropdownBodyProps>`
  background-color: ${({ theme }) => theme.colors.secondary};
  border: 1px solid ${({ theme }) => theme.colors.borderColor};
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  ${(props) => props.isOpen && 'border-top: none'};
  max-height: ${DROP_DOWN_HEIGHT}px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: absolute;
  z-index: ${({ theme }) => theme.zIndices.content};
`;

const DropdownItem = styled(Flex)`
  min-height: 43px;
  cursor: pointer;
  padding: ${({ theme }) => theme.space['2xs']}px
    ${({ theme }) => theme.space.xs}px;
  &:hover {
    background-color: ${(props) => props.theme.colors.optionHoverColor};
  }
`;

const CaretDown = styled.div<DropdownBodyProps>`
  background: url('${caretDown.src}') center no-repeat;
  background-color: ${({ isOpen, theme }) =>
    isOpen ? Palette.white : theme.colors.borderColor};
  position: absolute;
  right: 0;
  height: 100%;
  width: 24px;
  ${({ isOpen }) => isOpen && 'transform: rotate(180deg)'};
`;

export const Badge = styled(Text)`
  background-color: ${({ theme }) => theme.colors.badgeColor};
  border-radius: ${({ theme }) => theme.radii['3xs']};
  padding: 0 ${({ theme }) => theme.space['2xs']}px;
  height: fit-content;
`;

Badge.defaultProps = {
  variant: '7xsBold',
  color: 'secondary',
};

const selectedItemInitial = { value: '', label: '' };

Text.defaultProps = {
  variant: '3xs',
};

const DeselectButtonWrapper = styled(Flex)<
  FlexProps & React.HTMLProps<HTMLButtonElement>
>`
  padding: ${({ theme }) => theme.space['2xs']}px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 50%;
`;

type DeselectButton = {
  onDeselectClick: MouseEventHandler<HTMLButtonElement> &
    MouseEventHandler<HTMLDivElement>;
};

const DeselectButton = ({ onDeselectClick }: DeselectButton) => {
  return (
    <DeselectButtonWrapper
      position="absolute"
      right="l"
      top="none"
      onClick={onDeselectClick}
      as="button"
      type="button"
    >
      <Image
        src="/images/close-sm.svg"
        alt="close meal planner"
        width="22"
        height="22"
        priority={true}
      />
    </DeselectButtonWrapper>
  );
};

export const DropDownSelect: React.FC<DropdownSelectProps> = ({
  defaultValue,
  disabled,
  options,
  onChange,
  placeholder = '',
  fullWidth,
  error,
}) => {
  selectedItemInitial.label = placeholder;
  const [isOpen, setOpen] = useState(false);
  const [selectedItem, setSelectedItem] =
    useState<DropdownSelectOption>(selectedItemInitial);

  const dropdownRef = useRef(null);

  const toggleDropdown = () => setOpen(!isOpen);
  const closeDropdown = () => setOpen(false);

  useOnClickOutside(dropdownRef, closeDropdown, disabled);

  const handleItemClick = (item: DropdownSelectOption) => {
    const selected =
      (selectedItem && selectedItem.value == item.value) || !item
        ? selectedItemInitial
        : item;
    setSelectedItem(selected);
    onChange(selected);
    toggleDropdown();
  };

  const handleDeselect = (
    e: React.MouseEvent<HTMLButtonElement> & React.MouseEvent<HTMLDivElement>,
  ) => {
    e.stopPropagation();

    setSelectedItem(selectedItemInitial);
    onChange(selectedItemInitial);
  };

  // Note: set "defaultValue" (It may be undefined on initial render)
  useEffect(() => {
    if (!selectedItem?.value && defaultValue) {
      setSelectedItem(defaultValue);
    }
  }, [defaultValue]);

  const itemIsSelected =
    selectedItem && selectedItem.value !== selectedItemInitial.value;

  return (
    <Flex flexDirection="column">
      <DropDown
        ref={dropdownRef}
        disabled={disabled}
        backgroundColor="secondary"
        width={[
          '100%',
          '100%',
          '100%',
          fullWidth ? '100%' : `${DROP_DOWN_WIDTH}px`,
        ]}
      >
        <DropdownHeader isOpen={isOpen} onClick={toggleDropdown}>
          <Text variant="xs" color="textGray32">
            {selectedItem && selectedItem.label}
          </Text>
          {itemIsSelected && (
            <DeselectButton onDeselectClick={handleDeselect} />
          )}
          <CaretDown isOpen={isOpen} />
        </DropdownHeader>
        <DropdownBody
          isOpen={isOpen}
          width={['100%', '100%', '100%', `${DROP_DOWN_WIDTH}px`]}
        >
          {options.map((item: DropdownSelectOption) => (
            <DropdownItem
              justifyContent="space-between"
              alignItems="center"
              key={item.value}
              onClick={() => handleItemClick(item)}
            >
              <Box>
                <Text>{item.label}</Text>
                <Text variant="5xs" color="textSecondary">
                  {item.description}
                </Text>
              </Box>
              {item.isSale && <Badge>Sale</Badge>}
            </DropdownItem>
          ))}
        </DropdownBody>
      </DropDown>
      {error && (
        <Box minHeight="22px" marginTop="2xs">
          <Error as="span" variant={['4xs', '3xs', '3xs']}>
            {error}
          </Error>
        </Box>
      )}
    </Flex>
  );
};
