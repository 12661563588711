const reParsePxVal = /(\d+(?:\.\d+)?)px/;
/**
 * Gets the numerical value of a pixel css value.
 *
 * @param val
 * @returns
 */
export function getPxNumber(val: string): number | null {
  const matches = val.match(reParsePxVal);
  if (!matches || matches.length < 1) {
    return null;
  }
  const numVal = Number.parseFloat(matches[1]);
  return Number.isNaN(numVal) ? null : numVal;
}
