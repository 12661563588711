import { FC } from 'react';
import styled from '@emotion/styled';
import { Text } from '@/components/base/text';
import { TextLink } from '@/components/base/text-link';
import { Flex } from '@/components/base/flex';
import Link from 'next/link';

const StyledLink = styled(TextLink)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
  text-transform: initial;

  &:hover {
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.primaryLight200};

    > .subitem-icon {
      color: ${({ theme }) => theme.colors.white};
    }
  }

  > .subitem-icon {
    color: ${({ theme }) => theme.colors.primaryLight200};
  }
`;

type NavSubItemProps = {
  href: string;
  className?: string;
  icon?: React.ReactNode;
  title?: string;
  children?: React.ReactNode;
  onClick?: (evt: any) => void;
};

export const NavSubItem: FC<NavSubItemProps> = ({
  icon,
  className,
  title,
  children,
  href,
  onClick,
}) => {
  const isCustomElement = typeof children === 'object';
  const onClickProps = {
    ...(onClick && { onClick }),
  };

  return (
    <Link href={href}>
      <StyledLink
        {...onClickProps}
        href={href}
        className={className}
        paddingX="m"
        paddingY="s"
      >
        {!isCustomElement && icon && (
          <Flex mr={'xs'} alignItems="center" className="subitem-icon">
            {icon}
          </Flex>
        )}
        {!isCustomElement && title && <Text>{title}</Text>}
        {isCustomElement && children}
      </StyledLink>
    </Link>
  );
};
