import React, { useContext, useEffect, useState } from 'react';
import Link from 'next/link';
import { initialSearchOptions as initialRecipeSearchOptions } from '@/store/recipe-box-store';
import styled from '@emotion/styled';
import {
  faList,
  faSearch,
  faHeart,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as CSS from 'csstype';

import { Flex } from '@/components/base/flex';
import { Logo } from '@/components/base/logo';
import { Text } from '@/components/base/text';
import { NavItem, ScoreCardModal } from '@/components/navigation-bar';
import { Button } from '@/components/base/buttons';
import { PointsIndicator } from '@/components/points-indicator';
import { ProfileSubNavItem } from '@/components/navigation-bar/ProfileSubNavItem';
import {
  DELETE_PROFILE_MODAL_TYPE,
  RemoveProfileModal,
} from '@/components/navigation-bar/RemoveProfileModal';
import { NavSubItem } from '@/components/navigation-bar/NavSubItem';
import { NavShoppingList } from '@/components/grocery-list/NavShoppingList';
import {
  AddNewProfileModal,
  ADD_PROFILE_MODAL_TYPE,
} from '@/components/navigation-bar/AddNewProfileModal';
import { RemoveShoppingListModal } from '@/components/grocery-list/RemoveShoppingListModal';
import { ModalContext } from '@/context/modal';
import useCommandRequest from '@/hooks/use-command-request';
import removeProfile from '@/services/remove-profile';
import { ShoppingTrip } from '@/hooks/use-shopping-trips';
import { useHousehold } from '@/hooks/use-household';
import {
  HouseholdProfile,
  setPrebuiltProfiles,
  useHouseholdProfiles,
} from '@/hooks/use-household-profiles';
import { useViewportSizes } from '@/hooks/use-viewport-sizes';
import { useScoreCardStore } from '@/store/score-card-store';

import { LogoutOutIcon } from './LogoutIcon';
import { ProfileIcon } from './ProfileIcon';
import { DinnerRecipeBoxModal } from '@/components/meal-planner/DinnerRecipeBoxModal';
import { useMealPlanCalendarStore } from '@/store/meal-plan-calendar-store';
import { RateRecipesModal } from '@/components/navigation-bar/RateRecipesModal';

const HEADER_HEIGHT = '57px';

const StyleSvgRect = styled.rect``;

const BarButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.transparent};
  cursor: pointer;
  color: ${({ theme }) => theme.colors.iconBars};
  border-radius: ${({ theme }) => theme.radii['2xs']};
  padding: ${({ theme }) => theme.space.xs}px ${({ theme }) => theme.space.s}px;
  ${StyleSvgRect} {
    fill: ${({ theme }) => theme.colors.buttonGray35};
  }
  &:hover {
    color: ${({ theme }) => theme.colors.secondary};
    background-color: ${({ theme }) => theme.colors.iconBarsBg};
    ${StyleSvgRect} {
      fill: ${({ theme }) => theme.colors.secondary};
    }
  }
`;

const Container = styled(Flex)`
  background: ${({ theme }) => theme.colors.secondary};
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  margin-bottom: 1px;

  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.navigationHigh};

  ${({ theme }) => theme.mediaQueries.print} {
    box-shadow: none;
    ${Flex} {
      display: none;
    }
  }
`;

const StyledA = styled.a`
  display: flex;
  height: fit-content;
  margin-top: ${({ theme }) => theme.space.xs}px;
`;

const NavList = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.secondary};
  ${({ theme }) => theme.mediaQueries.l} {
    background-color: ${({ theme }) => theme.colors.transparent};
    position: initial;
  }
  left: 0;
  position: absolute;
  top: ${HEADER_HEIGHT};
  width: 100%;
  z-index: ${({ theme }) => theme.zIndices.content};
`;

const HamburgerIcon = () => {
  const width = 16;
  const height = 20;
  const rectHeight = 2.75;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <StyleSvgRect x="0" y="4" width={width} height={rectHeight} />
      <StyleSvgRect x="0" y="9" width={width} height={rectHeight} />
      <StyleSvgRect x="0" y="14" width={width} height={rectHeight} />
    </svg>
  );
};

const StyledCreateButton = styled(Button)`
  margin: 10px auto;
  width: 90%;
`;

const StyledPrintLogo = styled(Flex)`
  display: none;
  ${({ theme }) => theme.mediaQueries.print} {
    display: block;
  }
`;

export const NavigationBar: React.FC<{
  maxWidth?: string;
  position?: CSS.Property.Position;
}> = ({ maxWidth = '1023px', position }) => {
  const { household } = useHousehold();
  const {
    profiles = [],
    isLoading: profilesLoading,
    reloadProfiles = () => {},
  } = useHouseholdProfiles();
  const [showBar, setShowBar] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const { isLarge, isExtraLarge } = useViewportSizes();
  const isFirstTimeSetup = profiles.length === 0 && profilesLoading === false;
  const [showRateRecipesModal, setShowRateRecipesModal] = useState(false);

  // local state to maintain if built-in profile request is sent
  const [builtInProfileCreated, setBuiltInProfileCreated] = useState(false);

  const [profileToRemove, setProfileToRemove] =
    useState<HouseholdProfile | null>();

  const {
    type: addNewProfileType,
    isOpen: addNewProfileModal,
    closeModal: closeAddNewProfileModal,
    openModal: openAddNewProfile,
  } = useContext(ModalContext);
  const openAddNewProfileModal = () => {
    openAddNewProfile(null, ADD_PROFILE_MODAL_TYPE);
  };
  const addNewProfileModalOpen =
    addNewProfileModal && addNewProfileType === ADD_PROFILE_MODAL_TYPE;

  const viewScoreCardModal = useScoreCardStore(
    (state) => state?.viewScoreCardModal,
  );
  const setViewScoreCardModal = useScoreCardStore(
    (state) => state?.setViewScoreCardModal,
  );
  const recipeBoxIsOpen = useMealPlanCalendarStore(
    (state) => state.recipeBoxStatus === 'open',
  );
  const recipeBoxSearch = useMealPlanCalendarStore(
    (state) => state?.recipeBoxSearch,
  );
  const openRecipeBox = useMealPlanCalendarStore(
    (state) => state?.setRecipeBoxSearch,
  );

  const { isLoading: isRemoveProfileLoading, send: sendRemoveProfileCommand } =
    useCommandRequest();

  const onRemoveProfile = async () => {
    if (!profileToRemove) return;

    const result = await sendRemoveProfileCommand(() =>
      removeProfile(profileToRemove.id),
    );

    if (result.completed) {
      reloadProfiles();
      setProfileToRemove(null);
    }
  };

  const [shoppingTripToRemove, setShoppingTripToRemove] =
    useState<ShoppingTrip | null>(null);

  const openRemoveTripModal = (trip: ShoppingTrip) => {
    setShoppingTripToRemove(trip);
  };

  useEffect(() => {
    if (builtInProfileCreated) return;
    if (!household || !household.id || household.builtInProfilesCreated) return;
    setBuiltInProfileCreated(true);
    setPrebuiltProfiles(household.id).then(() => {
      // sending api request to create built-in profiles is async in backend
      // so adding some delay to load profiles as immediate fetch doesn't return profiles created
      setTimeout(() => {
        reloadProfiles && reloadProfiles();
      }, 1000);
    });
  }, [household, builtInProfileCreated]);

  useEffect(() => {
    setIsMobile(!isLarge && !isExtraLarge);
  }, [isLarge, isExtraLarge]);

  useEffect(() => {
    setShowBar(!isMobile);
  }, [isMobile]);

  const toggleBar = () => setShowBar(!showBar);

  return (
    <Container
      alignItems="center"
      justifyContent="center"
      position={position ?? 'fixed'}
    >
      <StyledPrintLogo>
        <Link href={'/'} passHref>
          <StyledA>
            <Logo width="90px" />
          </StyledA>
        </Link>
      </StyledPrintLogo>
      <Flex
        justifyContent="space-between"
        paddingX="s"
        maxWidth={maxWidth}
        height={HEADER_HEIGHT}
        width="100%"
      >
        <Link href={'/'} passHref>
          <StyledA>
            <Logo width="90px" />
          </StyledA>
        </Link>
        {(showBar || !isMobile) && (
          <NavList
            justifyContent="center"
            verticalAlign="center"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {isFirstTimeSetup === false && (
              <>
                <NavItem
                  text="planner"
                  href="/plan"
                  iconUrl="/images/planner.svg"
                />
                <NavItem text="recipes" iconUrl="/images/recipes.svg">
                  <NavSubItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openRecipeBox({
                        keyword: '',
                        defaultTabSelected: 0,
                        dinnerDate: undefined,
                        ...initialRecipeSearchOptions,
                      });
                    }}
                    icon={<FontAwesomeIcon icon={faSearch} size="sm" />}
                    title="Recipe Search"
                  />
                  <NavSubItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openRecipeBox({
                        keyword: '',
                        defaultTabSelected: 1,
                        dinnerDate: undefined,
                        ...initialRecipeSearchOptions,
                      });
                    }}
                    icon={<FontAwesomeIcon icon={faHeart} size="sm" />}
                    title="Favorites"
                  />
                  <NavSubItem
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowRateRecipesModal(true);
                    }}
                    icon={<FontAwesomeIcon icon={faStar} size="sm" />}
                    title="Rate Recipes"
                  />
                </NavItem>
                <NavItem text="profiles" iconUrl="/images/profile.svg">
                  <>
                    {profiles
                      .sort((profile) => (profile.isDefault ? -1 : 1))
                      .map((profile) => {
                        return (
                          <ProfileSubNavItem
                            key={profile.id}
                            data={profile}
                            confirmDelete={(profile) =>
                              setProfileToRemove(profile)
                            }
                          />
                        );
                      })}
                    <StyledCreateButton
                      variant="primary"
                      onClick={openAddNewProfileModal}
                    >
                      <Text>Create new profile</Text>
                    </StyledCreateButton>
                  </>
                </NavItem>
                <NavItem
                  text="pantry"
                  href="/pantry"
                  iconUrl="/images/pantry.svg"
                />
                <NavItem text="grocery" iconUrl="/images/grocery.svg">
                  <NavSubItem
                    href="/shopping_lists"
                    icon={<FontAwesomeIcon icon={faList} size="sm" />}
                    title="VIEW ALL GROCERY LIST"
                  />
                  <NavShoppingList onRemoveTrip={openRemoveTripModal} />
                </NavItem>
                <NavItem
                  text="blog"
                  href="https://wp2.dinnertime.com/content/table-talk/"
                  iconUrl="/images/blog.svg"
                />
                <NavItem
                  text="Help"
                  href="https://wp2.dinnertime.com/content/faqs"
                  iconUrl="/images/help.svg"
                />
              </>
            )}
            <NavItem iconUrl="/images/setting.svg">
              <NavSubItem
                href="/settings/edit"
                icon={<ProfileIcon />}
                title="My Settings"
              />
              <NavSubItem
                href="/logout"
                icon={<LogoutOutIcon />}
                title="Sign Out"
              />
            </NavItem>
          </NavList>
        )}
        {household && (
          <PointsIndicator
            score={household.monthlyPoints ?? 0}
            onClick={() => setViewScoreCardModal(true)}
          />
        )}
        {isMobile && (
          <Flex alignItems="center">
            <BarButton onClick={toggleBar}>
              <HamburgerIcon />
            </BarButton>
          </Flex>
        )}
      </Flex>

      {profileToRemove && (
        <RemoveProfileModal
          isOpen={!!profileToRemove}
          onClose={() => setProfileToRemove(null)}
          onRemove={onRemoveProfile}
          onToggle={() => setProfileToRemove(null)}
          data={profileToRemove}
          removeButtonLoading={isRemoveProfileLoading}
        />
      )}

      {addNewProfileModalOpen && (
        <AddNewProfileModal
          isOpen
          onClose={closeAddNewProfileModal}
          onToggle={
            addNewProfileModalOpen
              ? closeAddNewProfileModal
              : openAddNewProfileModal
          }
          data={profiles}
        />
      )}

      {shoppingTripToRemove && (
        <RemoveShoppingListModal
          isOpen
          onClose={() => {
            setShoppingTripToRemove(null);
          }}
          shoppingTrip={shoppingTripToRemove}
        />
      )}

      {viewScoreCardModal && (
        <ScoreCardModal isOpen onClose={() => setViewScoreCardModal(false)} />
      )}

      {recipeBoxIsOpen && (
        <DinnerRecipeBoxModal
          isOpen
          onClose={() => openRecipeBox(null)}
          dinnerDate={recipeBoxSearch?.dinnerDate}
          defaultTabSelected={recipeBoxSearch?.defaultTabSelected}
          onNewDate={(date) => {
            openRecipeBox({
              ...recipeBoxSearch,
              dinnerDate: date,
            });
          }}
        />
      )}

      {showRateRecipesModal && (
        <RateRecipesModal
          isOpen
          onClose={() => setShowRateRecipesModal(false)}
        />
      )}
    </Container>
  );
};
